import * as React from 'react';
import { Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import Projects from './components/sections/Projects';
import Achievements from './components/sections/Achievements';
import Contact from './components/sections/Contact';

import './custom.css'

export default () => (
    <Layout>
        <Route exact path='/' component={Home} />
        <Route exact path='/Projects' component={Projects} />
        <Route exact path='/Achievements' component={Achievements} />
        <Route exact path='/Contact' component={Contact} />
    </Layout>
);
