import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Contact from './sections/Contact';
import Home from './Home';
import Projects from './sections/Projects';
import Achievements from './sections/Achievements';

export default (props: { children?: React.ReactNode }) => (    
    <React.Fragment>        
        <NavMenu />
        <Container>
            {/*{props.children}*/} {/*Use this option if you want to move away from smooth scroll single page*/ }
            <Home />
            <Projects />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Achievements />
            <Contact />
        </Container>
    </React.Fragment>
);
