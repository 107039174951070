import * as React from 'react';
import { connect } from 'react-redux';
import Wave from './imgs/matter/wavingHand.png'; //with import...TCDW
//import Grizzly from './imgs/matter/Logo-Sharp.png'; //with import...TCDW
import USA from './imgs/matter/usaloved.png'; //with import...TCDW
import PBJLife from './imgs/matter/PBJLife.png'; //with import...TCDW
import PDFLogo from './imgs/matter/pdfLogo.png'; //with import...TCDW
import Remotely from './imgs/matter/Remote.png'; //with import...TCDW
import SplashMe from './imgs/matter/SplashInteract.svg';

const Home = () => (
    <div id="homeSection" className="centerConsole">
        <div className="designStaticOne"><img src={SplashMe} className="reactLogo" /></div>
        <p>Thank you, <img src={Wave} width="48" height="48" />your visit to this site is greatly appreciated; the purpose of this site is to allow some functional usage of practical applications I've built or was involved with, from independent to team-based previous assignments completed or assisted or seen.  I plan to expand my career by helping others improve their company and services while growing my businesses. <br /></p>
        <div className="myNameControlled"><h1>You can call me, Walker</h1>{/*<img src={Grizzly} width="32" height="48" />*/}</div>
        <h4>Solutions Architect/Full Stack Software Engineer</h4>
        <p>C# &#183;.Net Core &#183; AWS &#183; T-SQL &#183; Postgres SQL &#183; C++ &#183; Oracle &#183; Vue &#183; React &#183; Angular &#183; JSX &#183; JS &#183; Typescript &#183; PHP &#183; APIs &#183; Python &#183; R</p>
        <p>Working <img src={Remotely} width="24" height="24" /> remotely from <img src={USA} width="32" height="32" />to provide the most performant and solid solutions.<img src={PBJLife} width="48" height="48" /></p>
        <div className="pageNavbtn">
            <a href="https://drive.google.com/file/d/1FJP3g8ca7mdcA87k_bk_mAZORnT_2o5e/view?usp=sharing" className="resume-btn">
                <label>
                    See My Resume<img src={PDFLogo} width="18" height="18" />
                </label>
            </a>
        </div>
        <div className="hostedServices">
            <p>
                <a href="https://cp.hostek.com/aff.php?aff=1775&plat=VPS">
                    <img src="https://hostek.com/banners/ht_320x35.gif" className="hostekImg" />
                </a>.
            </p>
        </div>
    </div>
);

export default connect()(Home);
