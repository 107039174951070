import * as React from 'react';
import { connect } from 'react-redux';
import Walker from '../imgs/matter/Walker.png'; //with import...TCDW
import mailIcon from '../imgs/matter/mail.png';
import Upwork from '../imgs/vector/Upwork_logo.svg';

const Contact = () => (
        <div id="contactSection" className="centerConsole">
            <h1>-- Contact Walker &#8212;</h1>
            <div className="designStaticTwo">
            <img src={Walker} width="300" height="400" className="walkerimg" />
            </div>
            <div className="socialBanner">
                <a href="https://www.upwork.com/freelancers/~0116fa1a86a5c32db9?viewMode=1" target="_blank" title="Upwork.com Profile">
                    <img src={Upwork} width="64" height="28" className="greyBall" alt="Upwork.com Work Site" />
                </a>
                <img src={mailIcon} className="greyBall" alt="Email me" onClick={() => { window.location.href = "mailto:solutionsarchitect.engineering@tadeochristopher.dev" }} />  &nbsp;          
                <a href="https://www.guru.com/freelancers/tadeochristopher-d-walker" title="Guru.com Profile" target="_blank">
                    <img src="https://img-guru.com/20221227.3/images/Guru-logo2.png" width="64" height="28" className="greyBall" alt="Grur.com Work Site" />
                </a>
            </div>
            <div className="hostedServices">
                <p>
                    <a href="https://cp.hostek.com/aff.php?aff=1775&plat=VPS"><img src="https://hostek.com/banners/ht_320x35.gif" className="hostekImg" /></a>.
                </p>
            </div>            
        </div>
)

export default connect()(Contact);