import * as React from 'react';
import { connect } from 'react-redux';
//import { useRef, useEffect } from 'react';
import CertificationLogo from '.././imgs/matter/certificationsymbol.png';
import CapGraduate from '.././imgs/vector/Diploma.png';
import CodAddict from '.././imgs/matter/CodeAddict.png';

const Achievements = () => ( 
    <div id="achievementSection" className="centerConsole">
        <h1>-- Achievements &#8212;</h1>
        <div className="designStaticTwo">
            <div className="achievements-container" id="achievementsContent">
                <div className="achievements-container-left">
                    <h3>&#8212;Certifications <img src={CapGraduate} width="64" height="54" /></h3>
                    <div className="achievements-section">
                        <p>Full Stack and Architect Certifications from various developer testing locations.</p>
                    </div>
                    <div className="achievements-center">
                        <ul>
                            <li>
                                <a href="https://www.freecodecamp.org/certification/tadeochristopherwalker/responsive-web-design" id="achievement-links" target="_blank">
                                    <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> Responsive Web Design Certification
                                </a>
                            </li>
                            <li>
                                <a href="https://learn.microsoft.com/en-us/users/tadeochristopherwalker-4597/" id="achievement-links" target="_blank">
                                    <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> Microsoft Certifications
                                </a>
                            </li>
                            <li>
                                <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> APIs and Microservices Certification
                            </li>
                            <li>
                                <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> Data Visualization Certification
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="achievements-container-middle">
                    <h3>&#8212;Developer Challenge <img src={CodAddict} width="48" height="48" /></h3>
                    <div className="achievements-section">
                        <p>Competitive Challenges help to keep the mind strong and sharp; visit my results.  We love those Stats and Analytic Reporting!</p>
                    </div>
                    <ul>
                        <li><a href="https://online.roberthalf.com/s/profile?a=RH&c=US&d=en_US&language=en_US&redirect=false" target="_blank">RHT Exams Profile</a></li>
                        <li><a href="https://www.hackerrank.com/c0walk10" target="_blank">HackerRank</a></li>
                        <li><a href="https://www.codeproject.com/script/Membership/View.aspx?mid=11323097" target="_blank">CODE Project</a></li>
                        <li><a href="https://leetcode.com/c0walk10/" target="_blank">LeetCode</a></li>
                        <li><a href="https://profile.codersrank.io/user/apprehensivegent/" target="_blank">CodersRank</a></li>
                    </ul>
                </div>
                <div className="achievements-container-right">
                    <h3>&#8212;Education Track <img src={CapGraduate} width="64" height="54" /></h3>
                    <div className="achievements-section">                        
                        <p>There exist education and training within and before years shown here not listed that involve science and medicine.</p>
                    </div>
                    <ul>
                        <li>
                            <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> Brown University 2003
                        </li>
                        <li>
                            <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> RETS Institute Technology 2002
                        </li>
                        <li>
                            <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> Sullivan University 2004
                        </li>    
                        <li>
                            <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> Sullivan University 2005
                        </li>  
                        <li>
                            <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> University of Louisville 2021
                        </li>
                        <li>
                            <img id="certification-img" src={CertificationLogo} alt="Certification Logo" width="32" height="32" /> TBD...
                        </li>
                    </ul>
                </div>
             </div>
        </div>
    </div>
);

export default connect()(Achievements);