import * as React from 'react';
import { connect } from 'react-redux';
import githubSVG from '.././imgs/vector/githublogo_custom.svg';
import coderIcon from '.././imgs/matter/icons8-code.png';
import reactIcon from '.././imgs/vector/React.svg';
import angularIcon from '.././imgs/vector/Angular.svg';
import vueIcon from '.././imgs/vector/Vue.svg';
import xamarinIcon from '.././imgs/vector/Xamarin.svg';
import csharpIcon from '.././imgs/vector/CSharp.svg';
import tsql from '.././imgs/vector/TSQL.svg';
import apiIcon from '.././imgs/vector/APIs.svg';
import awsIcon from '.././imgs/vector/AWS.svg';
import coreIcon from '.././imgs/vector/Core.svg';
import lambdaIcon from '.././imgs/vector/Lambda.svg';
import appleIcon from '.././imgs/vector/apple.svg';
import pythonIcon from '.././imgs/vector/Python.svg';
import html5Icon from '.././imgs/vector/HTML5I.svg';
import jsonIcon from '.././imgs/vector/JSON.svg';
import jsIcon from '.././imgs/vector/JS.svg';
import architectIcon from '.././imgs/vector/Architect.svg';
import nodeIcon from '.././imgs/vector/Node.svg';
import psIcon from '.././imgs/vector/PS.svg';
import rIcon from '.././imgs/vector/R-Stats.svg';
import saasIcon from '.././imgs/vector/Saas.svg';
import androidIcon from '.././imgs/vector/android.png';
import gatewayIcon from '.././imgs/vector/Gateway.svg';
import sassIcon from '.././imgs/vector/sasss.svg';
import postgresIcon from '.././imgs/vector/PostgresSQL.svg';
import oracleIcon from '.././imgs/vector/Oracle.svg';
import azureIcon from '.././imgs/vector/Azure.svg';

const shakeGithub1 = document.getElementById("gitHub_logo1");
const shakeGithub2 = document.getElementById("gitHub_logo2");
const shakeGithub3 = document.getElementById("gitHub_logo3");

//function viewProj(proj:any) {
//    let link = proj.querySelector("#projects .project-box__link");
//    link.classList.remove("imageShake");
//    setTimeout(() => {
//        link.classList.add("imageShake");
//    }, 200);
//}

const Projects = () => (        
    <div id="projectsSection" className="centerConsole">        
            <h1><img src={coderIcon} className="projects-Code-Icon" width="32" height="32" /> Projects &#8212;</h1>
            <div className="designStaticTwo">
                <div className="project-containers">
                    <div className="project-container-left" onClick={() => { shakeGithub1!.classList.toggle("imageShake"); }}>
                        <h3>Adroid & IOS Apps</h3>
                        <div className="repoGithub">
                            <a href="https://github.com/tadeochristopher" target="_blank">
                                <img className="projectLogo" src={githubSVG} alt="github logo" width="32" height="32" id="gitHub_logo1" />
                            </a>
                        </div>
                        <p>Development and Architect of solutions and services for Large and Small organizations.</p>
                        <div className="divider">&nbsp;</div>
                        <div className="servicesList">
                            <ul>
                                <li>Provider Rep Queue App</li>
                                <li>Invoice Services App</li>
                                <li>Claims App</li>
                                <li>eLearning App</li>
                                <li>Forecasting App</li>
                                <li>MARA Health 2 App</li>
                                <li>HCCM App</li>
                            </ul>
                        </div>
                        <div className="divider">&nbsp;<h5>Under construction and Obtaining Permissions</h5></div>
                        <div className="projectFooter">
                            <img src={reactIcon} width="52" height="52" alt="React mobile development" />
                            <img src={angularIcon} width="52" height="52" alt="Angular mobile development" />
                            <img src={vueIcon} width="52" height="52" alt="Vue" />
                            <img src={xamarinIcon} width="52" height="52" alt="Xamarin" />
                            <img src={csharpIcon} width="52" height="52" alt="C Sharp" />
                            <img src={tsql} width="52" height="52" alt="T-SQL" />
                            <img src={appleIcon} width="48" height="48" alt="Apple" />
                            <img src={awsIcon} width="52" height="52" alt="AWS" />
                            <img src={androidIcon} width="48" height="48" alt=".Net Core" />
                        </div>
                    </div>
                    <div className="project-container-middle" onClick={() => { shakeGithub2!.classList.toggle("imageShake"); }}>
                        <h3>Client Service Portals</h3>
                        <div className="repoGithub">
                            <a href="https://github.com/tadeochristopher" target="_blank">
                            <img className="projectLogo" src={githubSVG} alt="github logo" width="32" height="32" id="gitHub_logo2" />
                            </a>
                        </div>
                        <p>Architect, Developed and Maintained these microservices, web portals, and desktop products for private, government, and corporate companies.</p>
                        <div className="servicesList">
                            <ul>
                                <li>Provider Rep Queue</li>
                                <li>Workforce ADP</li>
                                <li>Tool and Die</li>
                                <li>Tut Carousel</li>
                                <li>Cakes by Nessa</li>
                                <li>Child of God Blog</li>
                            </ul>
                        </div>
                        <div className="divider">&nbsp;<h5>Under construction and Configuring Cloud Space</h5></div>
                        <div className="projectFooter">
                            <img src={apiIcon} width="52" height="52" alt="API Services" />
                            <img src={lambdaIcon} width="52" height="52" alt="Lambda Functions" />
                            <img src={coreIcon} width="52" height="52" alt=".Net Core" />
                            <img src={csharpIcon} width="52" height="52" alt="C Sharp" />
                            <img src={tsql} width="52" height="52" alt="T-SQL" />
                            <img src={html5Icon} width="52" height="52" alt="HTML 5" />
                            <img src={jsIcon} width="52" height="52" alt="JavaScript" />
                            <img src={rIcon} width="52" height="52" alt="R Stats" />
                            <img src={sassIcon} width="52" height="52" alt="Sass" />
                            <img src={azureIcon} width="52" height="52" alt="Azure" />
                        </div>
                    </div>
                    <div className="project-container-right" onClick={() => { shakeGithub3!.classList.toggle("imageShake"); }}>
                        <h3>Data & API Services</h3>
                        <div className="repoGithub">
                            <a href="https://github.com/tadeochristopher" target="_blank">
                                <img className="projectLogo" src={githubSVG} alt="github logo" width="32" height="32" id="gitHub_logo3" />
                            </a>                            
                        </div> 
                        <p>Development and Architect of solutions and services for Large and Small organizations.</p>
                        <div className="servicesList">
                            <ul>
                            <li>Invoice Services</li>
                            <li>Payment Gates</li>
                            <li>Data Management</li>
                            <li>API Services</li>
                            <li>Forecasting</li>
                            <li>MARA Health 2 App</li>
                            <li>Cardio Echo Services</li>
                            <li>Automation Purchase Requisition</li>
                            <li>Invoice Services</li>
                            <li>Administration Portal AI</li>
                            <li>Database Demo</li>
                            <li>Asmx Service Demo</li>
                            <li>First AM</li>
                            </ul>
                        </div>
                        <div className="divider">&nbsp;</div>
                        <div className="projectFooter">
                            <img src={apiIcon} width="52" height="52" alt="API Services" />
                            <img src={lambdaIcon} width="52" height="52" alt="Lambda Functions" />
                            <img src={coreIcon} width="52" height="52" alt=".Net Core" />
                            <img src={csharpIcon} width="52" height="52" alt="C Sharp" />
                            <img src={tsql} width="52" height="52" alt="T-SQL" />
                            <img src={gatewayIcon} width="52" height="52" alt="Gateway" />
                            <img src={architectIcon} width="52" height="52" alt="Architect" />
                            <img src={saasIcon} width="52" height="52" alt="Saas - Software as a Service" />
                            <img src={pythonIcon} width="52" height="52" alt="Python" />
                            <img src={jsonIcon} width="52" height="52" alt="JavaScript JSON" />
                            <img src={nodeIcon} width="52" height="52" alt="Node" />
                            <img src={psIcon} width="52" height="52" alt="PowerShell" />
                            <img src={oracleIcon} width="52" height="52" alt="Oracle" />
                            <img src={postgresIcon} width="52" height="52" alt="Postgres SQL" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
);

export default connect()(Projects);