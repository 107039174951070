import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, Route } from 'react-router-dom';
import './NavMenu.css';
import styles from '../styles/Header.module.css';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };
        
    //How to fix TS "object is possibly null"
    /*
     * https://timmousk.com/blog/typescript-object-is-possibly-null/
     * let myName: string | undefined | null

       console.log(myName ?? 'Tim');

        const myName = 'Tim' as string | undefined;

        // Outputs: 3
        console.log(myName!.length); 
     * */
    //manageContactsRoute = () => {
    //    const element = document.getElementById("contactSection");
        
    //    element!.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
    //};

    public render() {
        const HandleHomeClickRoute = () => { document.getElementById('homeSection')!.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); }
        const HandleProjectsClickRoute = () => { document.getElementById('projectsSection')!.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); }
        const HandleAchievementsClickRoute = () => { document.getElementById('achievementSection')!.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); }
        const HandleContactClickRoute = () => { document.getElementById('contactSection')!.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" }); }
        
        return (
            <header className={styles.header}>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <Container>
                        <a id="developerservices" href="https://tadeochristopher.dev" target="_self" rel="noreferrer noopener" className="text-light">Tadeochristopher</a>
                        <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink id="Home" tag={Link} className="text-light" to="/" onClick={HandleHomeClickRoute}>Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink id="Projects" tag={Link} className="text-light" to="/" onClick={HandleProjectsClickRoute}>Projects</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink id="Achievements" tag={Link} className="text-light" to="/" onClick={HandleAchievementsClickRoute}>Achievements</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink id="Contact" tag={Link} className="text-light" to="/" onClick={HandleContactClickRoute}>Contact</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
